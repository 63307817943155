/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/ban-types */

import type { DetailedHTMLProps, HTMLAttributes } from 'react';

import { prune } from '@proptly/shared';

import { ActionType, FormActionType, isFormAction } from './actions';
import { ipc } from './ipc';

const css = `
proptly-form,proptly-routing-form{
  display: block;
}
proptly-form > iframe,
proptly-routing-form > iframe{
  width: 100%;
  border: unset;
}
`;

export type FormHeight = 'formHeight' | (string & {});
export type FormTarget = 'contact' | 'project';

const checkIsFormTarget = (target: string | null): target is FormTarget => {
  return !!target && ['contact', 'project'].includes(target);
};

const observedAttributes = [
  'height',
  'slug',
  'primary-color',
  'border-radius',
  'variant',
  'lng',
  'target',
  'default-target',
] as const;
type ObservedAttribute = (typeof observedAttributes)[number];

let count = 0;

export class ProptlyFormElement extends HTMLElement {
  iframe: HTMLIFrameElement;
  formId: string;
  formHeight: number | null = null;
  slug: string;

  'primary-color': string | null = null;
  'border-radius': string | null = null;
  variant: string | null = null;
  lng: string | null = null;
  target: FormTarget | null;
  defaultTarget: FormTarget | null;
  isRouting = false;

  static get observedAttributes() {
    return observedAttributes;
  }

  constructor() {
    super();
    count++;
    this.formId = `proptly-form-${count}`;
    this.iframe = document.createElement('iframe');
  }

  connectedCallback() {
    this.appendChild(this.iframe);
    ipc.on(this.windowMessageListener);
  }

  disconnectedCallback() {
    ipc.off(this.windowMessageListener);
  }

  attributeChangedCallback(
    attribute: ObservedAttribute,
    oldValue: null | string,
    newValue: null | string,
  ) {
    switch (attribute) {
      case 'height': {
        this.updateHeight(newValue);
        break;
      }
      case 'slug': {
        this.updateSlug(newValue);
        break;
      }
      case 'target': {
        this.updateTarget(newValue);
        break;
      }
      case 'default-target': {
        this.updateDefaultTarget(newValue);
        break;
      }
      default: {
        this[attribute] = newValue;
        this.updateSrc();
        break;
      }
    }
  }

  updateHeight(height: FormHeight | null) {
    if (!height || height === 'formHeight') {
      this.iframe.height = `${this.formHeight}px`;
    } else {
      this.iframe.height = height;
    }
  }

  updateSlug(slug: string | null) {
    if (!slug) {
      throw new Error('slug attribute is required');
    }
    this.slug = slug;
    this.updateSrc();
  }

  updateTarget(target: string | null) {
    if (checkIsFormTarget(target)) {
      this.target = target;
    } else {
      this.target = null;
    }
    this.updateSrc();
  }

  updateDefaultTarget(target: string | null) {
    if (checkIsFormTarget(target)) {
      this.defaultTarget = target;
    } else {
      this.defaultTarget = null;
    }
    this.updateSrc();
  }

  updateSrc() {
    const paramsObject = prune({
      formId: this.formId,
      slug: this.slug,
      primaryColor: this['primary-color'],
      borderRadius: this['border-radius'],
      variant: this.variant,
      lng: this.lng,
      target: this.target,
      defaultTarget: this.defaultTarget,
    }) as Record<string, string>;

    const searchParams = new URLSearchParams(paramsObject);

    const src = this.isRouting
      ? `https://sdk.proptly.tech/order/?${searchParams.toString()}`
      : `https://sdk.proptly.tech/form/?${searchParams.toString()}`;

    this.iframe.setAttribute('src', src);
  }

  windowMessageListener = (e: MessageEvent<FormActionType>) => {
    const action = e.data;
    if (!isFormAction(action)) {
      return;
    }
    if (action.formId != this.formId) {
      return;
    }
    switch (action.type) {
      case ActionType.SetFormHeight: {
        this.formHeight = action.payload;
        this.updateHeight(this.getAttribute('height'));
        break;
      }
      case ActionType.FormSubmitSuccess: {
        this.dispatchEvent(new Event('success'));
      }
    }
  };

  static init() {
    const register = () => {
      if (
        !window.customElements.get('proptly-form') &&
        !window.customElements.get('proptly-routing-form')
      ) {
        const styles = document.createElement('style');
        styles.innerHTML = css;
        document.head.appendChild(styles);
      }
      if (!window.customElements.get('proptly-form')) {
        window.customElements.define('proptly-form', ProptlyFormElement);
      }
      if (!window.customElements.get('proptly-routing-form')) {
        window.customElements.define(
          'proptly-routing-form',
          ProptlyRoutingFormElement,
        );
      }
    };

    window.addEventListener('load', register);
    register();
  }
}

class ProptlyRoutingFormElement extends ProptlyFormElement {
  override isRouting = true;
}

type CustomElement<T> = DetailedHTMLProps<HTMLAttributes<T>, T>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['proptly-form']: CustomElement<ProptlyFormElement> & {
        slug: string;
        height?: FormHeight;
        'background-color'?: string;
        'primary-color'?: string;
        'border-radius'?: string;
        variant?: 'filled' | 'outline';
        target?: FormTarget;
        'default-target'?: FormTarget;
      };
    }
  }
}
